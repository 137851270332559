import HeatmapActivity from '@/components/HeatmapActivity/HeatmapActivity';
import { Flex, useThemeCustomProperties } from '@blockanalitica/ui';
import { HeatmapActivityData } from '@blockanalitica/ui/package/esm/src/components/server-components/HeatmapActivity/HeatmapActivity';
import { useMemo } from 'react';

type HeatmapProps = {
  data: HeatmapActivityData[];
  monthsAgo?: number;
  onClickCallback?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    { date }: HeatmapActivityData
  ) => void;
};

export default function Heatmap({
  data,
  monthsAgo = 12,
  onClickCallback
}: HeatmapProps) {
  const colors = useThemeCustomProperties([
    'color-primary',
    'color-background'
  ]);
  const total = useMemo(
    () => data.reduce((sum, row) => sum + Number(row.value), 0),
    [data]
  );

  const dateText = useMemo(() => {
    if (monthsAgo === 12) {
      return 'year';
    }
    return `${monthsAgo} months`;
  }, [monthsAgo]);

  return (
    <Flex direction="column" alignItems="flex-end" gap="space-3xs">
      <HeatmapActivity
        data={data}
        options={{
          monthsAgo: monthsAgo,
          heatColor: colors.colorPrimary,
          backgroundColor: colors.colorBackground,
          onClick: onClickCallback
        }}
      />
      <span className="text-2 text-muted">
        {total} events in the last {dateText}
      </span>
    </Flex>
  );
}
