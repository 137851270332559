import { useApi } from '@/api';
import { Heatmap } from '@/components/charts';
import LinkButtonHome from '@/components/forms/LinkButtomHome';
import { CardLoader, Flex, useIsMobile } from '@blockanalitica/ui';
import { useNavigate } from 'react-router-dom';

export default function ActivityHeatmap() {
  const isMobile = useIsMobile();
  const monthsAgo = isMobile ? 3 : 12;

  let [data] = useApi({
    url: `/activity/`
  });
  const navigate = useNavigate();

  function onClickCallback(
    //@ts-ignore
    e,
    { date }
  ) {
    navigate(`/activity/?date=${date.split('T')[0]}`, { relative: 'path' });
  }

  if (data) {
    return (
      <Flex gap="space-xs" direction="column">
        <Flex gap="space-xs" justifyContent="space-between" alignItems="center">
          <h4>Events Activity</h4>
          <LinkButtonHome to="activity">view all</LinkButtonHome>
        </Flex>
        <Flex
          direction={['row', 'column', 'column']}
          gap="space-m"
          justifyContent="center">
          <Heatmap
            data={data}
            monthsAgo={monthsAgo}
            onClickCallback={onClickCallback}
          />
        </Flex>
      </Flex>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
