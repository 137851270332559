import { Page } from '@/components';
import { Flex } from '@blockanalitica/ui';
import EventsTable from './eventsTable';

export default function MarketActivityPage() {
  return (
    <Page
      title={
        <Flex gap="space-s" alignItems="center">
          <h1>Market Events</h1>
        </Flex>
      }
      isTimeSwitch={false}>
      <EventsTable />
    </Page>
  );
}
