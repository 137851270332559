import { useApi } from '@/api';
import { EventFormatter, Link } from '@/components';
import DoubleSymbol from '@/components/DoubleSymbol/DoubleSymbol';
import EventName from '@/components/events/EventName';
import SelectFilter from '@/components/filters/SelectFilter';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { ALL_EVENTS, EVENT_TYPE_MAPPING } from '@/constants';
import { useSearchParams } from '@/hooks';
import { symbolMapping, titleCase } from '@/utils';
import {
  CryptoIcon,
  Flex,
  RelativeDateTime,
  WalletAddress,
  useTableProps
} from '@blockanalitica/ui';
import { useMemo } from 'react';

export default function EventsTable() {
  const selectFilterOptions = useMemo(() => {
    let options = [{ label: 'All events', value: '' }];
    options.push(
      ...ALL_EVENTS.map((event) => ({
        label: EVENT_TYPE_MAPPING[event],
        value: event
      }))
    );
    return options;
  }, []);

  const { sorting, filters, pagination } = useTableProps({
    pageSize: 25,
    filtersDebounceTime: 50
  });

  const [date] = useSearchParams('date', '');

  const [data, error] = useApi({
    url: `/events`,
    queryParams: {
      p: String(pagination.currentPage),
      p_size: String(pagination.pageSize),
      event: filters.filtersApplied.eventType,
      order: sorting.currentSorting,
      date: date
    }
  });

  const content = useMemo(() => {
    if (data) {
      const columns = [
        {
          header: 'NETWORK',
          getCell: (row) => (
            <Flex gap="space-xs" alignItems={'center'}>
              <CryptoIcon name={symbolMapping[row.network]} size={30} />
              {titleCase(row.network)}
            </Flex>
          )
        },
        {
          header: 'WALLET',
          getCell: (row) => (
            <WalletAddress
              options={{
                size: 'xSmall'
              }}
              address={row.wallet_address}
            />
          )
        },
        {
          header: 'MARKET',
          getCell: (row) => (
            <Flex gap="space-3xs" alignItems={'center'}>
              <DoubleSymbol
                symbol1={<CryptoIcon name={row.asset_symbol} extension="png" />}
                symbol2={
                  <CryptoIcon
                    name={row.yield_source ? row.yield_source : ''}
                    extension="png"
                  />
                }
              />
              {row.name}
            </Flex>
          )
        },
        {
          header: 'EVENT',
          getCell: (row) => <EventName event={row.event} />
        },
        {
          header: 'DETAILS',
          getCell: (row) => <EventFormatter type={row.event} data={row} />,
          cellSize: '2.5fr'
        },
        {
          header: 'TIME',
          getCell: (row) => <RelativeDateTime value={row.datetime} />,

          cellAlign: 'flex-end',
          headerAlign: 'flex-end',
          sortingField: 'order_index'
        }
      ];
      return (
        <Table
          rows={data['results']}
          columns={columns}
          getKey={(row) => `${row.tx_hash}`}
          sorting={sorting}
          pagination={{
            ...pagination,
            totalRecords: data.count
          }}
          getLinkWrapper={(row) => (
            <Link daysAgo={true} to={`/wallets/${row.wallet_address}`} />
          )}
        />
      );
    }
    return <TableLoader />;
  }, [data, sorting, pagination]);

  if (error) {
    throw error;
  }

  return (
    <>
      {date && (
        <h4>
          Events for{' '}
          {new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
          })}
        </h4>
      )}
      <Flex direction={['column']} gap={'space-xs'}>
        <Flex justifyContent="flex-end" alignItems="center">
          <SelectFilter
            key="event-filter"
            placeholder="Showing all events"
            filterField="eventType"
            options={selectFilterOptions}
            onPageChange={pagination.onPageChange}
            {...filters}
          />
        </Flex>
        {content}
      </Flex>
    </>
  );
}
