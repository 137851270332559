import { useApi } from '@/api';
import { Divider, Page } from '@/components';
import KpiCurrencyCard from '@/components/Kpi/KpiCurrencyCard';
import { useSearchParams } from '@/hooks';
import { Box, CurrencyFilter, Flex, useTableProps } from '@blockanalitica/ui';
import ChartTabs from './components/ChartTabs';
import MarketsTable from './components/MarketsTable';

export default function HomePage() {
  const [daysAgo] = useSearchParams('days_ago', '7');

  const [data, error] = useApi({
    url: `/overall/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  const { filters } = useTableProps({
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });

  if (data) {
    return (
      <Page>
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Box flexBasis={['33%', '100%', '100%']}>
            <KpiCurrencyCard
              title="TVL"
              data={data}
              field="tvl"
              currency="USD"
              options={{
                valueSize: 'text-5',
                trendSize: 'text-3',
                footerSize: 'text-2',
                dynamic: true
              }}
            />
          </Box>
          <Box flexBasis={['33%', '100%', '100%']}>
            <KpiCurrencyCard
              title="Liquidity"
              data={data}
              field="liquidity"
              currency="USD"
              options={{
                valueSize: 'text-5',
                trendSize: 'text-3',
                footerSize: 'text-2',
                dynamic: true,
                notation: 'compact'
              }}
            />
          </Box>
          <Box flexBasis={['33%', '100%', '100%']}>
            <KpiCurrencyCard
              title="Trading Volume (All Time)"
              data={data}
              field={`volume_365d`}
              currency="USD"
              options={{
                valueSize: 'text-5',
                trendSize: 'text-3',
                footerSize: 'text-2',
                dynamic: true,
                notation: 'compact'
              }}
            />
          </Box>
        </Flex>
        <Divider />
        <ChartTabs daysAgo={daysAgo} trading={data[`volume_${daysAgo}d`]} />
        <Divider />
        <Flex gap="space-s" justifyContent="space-between" alignItems="center">
          <h4>Markets</h4>
          <CurrencyFilter
            key="currency-filter"
            width="8rem"
            height="1.65rem"
            currency2={{ value: 'token', displayValue: 'Token' }}
            currency1={{ value: 'USD', displayValue: 'USD' }}
            {...filters}
          />
        </Flex>
        <MarketsTable daysAgo={daysAgo} filters={filters} />
      </Page>
    );
  }

  return null;
}
