import { useApi } from '@/api';
import { Divider, Page } from '@/components';
import KpiCurrencyCard from '@/components/Kpi/KpiCurrencyCard';

import Logo from '@/assets/logos/hyperdrive.svg?react';
import { useSearchParams } from '@/hooks';
import { Flex } from '@blockanalitica/ui';
import { Link } from 'react-router-dom';
import ActivityHeatmap from './ActivityCard';
import ChainsTable from './components/ChainTable';
import OverallChartTabs from './components/OverallChartTabs';
// import ChainTable from './ChainTable';

export default function HyperdriveOverall() {
  const [daysAgo] = useSearchParams('days_ago', 7);
  const [data, error] = useApi({
    url: `/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        logo={
          <Link to={'/'}>
            <Logo height={60} />
          </Link>
        }>
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <KpiCurrencyCard
            title="TVL"
            data={data}
            field="tvl"
            currency="USD"
            options={{
              valueSize: 'text-5',
              trendSize: 'text-3',
              footerSize: 'text-2',
              dynamic: true
            }}
          />
          <KpiCurrencyCard
            title="Liquidity"
            data={data}
            field="liquidity"
            currency="USD"
            options={{
              valueSize: 'text-5',
              trendSize: 'text-3',
              footerSize: 'text-2',
              dynamic: true
            }}
          />
          <KpiCurrencyCard
            title="Trading Volume (All Time)"
            data={data}
            field="volume"
            currency="USD"
            options={{
              valueSize: 'text-5',
              trendSize: 'text-3',
              footerSize: 'text-2',
              dynamic: true
            }}
          />
        </Flex>

        <OverallChartTabs
          daysAgo={daysAgo}
          trading={data[`volume_${daysAgo}d`]}
        />
        <Divider />
        <ChainsTable daysAgo={daysAgo} />
        <Divider />
        <ActivityHeatmap />
      </Page>
    );
  }

  return <></>;
}
