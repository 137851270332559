import { Flex, XIcon } from '@blockanalitica/ui';
import styles from './FixedSidebarLayout/FixedSidebarLayout.module.scss';
// @ts-ignore
import { Outlet } from 'react-router-dom';
// @ts-ignore
import BALogo from '@/assets/logos/BA-logo-full-white.svg?react';
// @ts-ignore
import Substack from '@/assets/logos/substack.svg?react';
import classnames from 'classnames';

export default function OverallLayout({ children }) {
  const currentYear = new Date().getFullYear();
  return (
    <Flex
      className={styles.homeContentContainer}
      gap="space-s"
      direction="column"
      justifyContent="space-between">
      <main>{children ? children : <Outlet />}</main>
      <footer>
        <Flex
          direction="row"
          gap="space-2xs"
          alignItems="center"
          justifyContent="center">
          <Flex alignItems="center" gap="space-2xs">
            <a
              href="https://twitter.com/BlockAnalitica"
              className="text-muted"
              target="_blank"
              rel="noreferrer">
              <XIcon size={16} />
            </a>
            <a
              href="https://blockanalitica.substack.com/"
              className="text-muted"
              target="_blank"
              rel="noreferrer">
              <Substack height={16} />
            </a>
          </Flex>
          <div className="text-muted">|</div>
          <Flex alignItems="center" gap="space-2xs">
            <a
              href="https://blockanalitica.com"
              className="text-muted"
              target="_blank"
              rel="noreferrer">
              <BALogo className={classnames(styles.logo)} height={30} />
            </a>
            <span className="text-muted text-1">
              © {currentYear}. All rights reserved.
            </span>
          </Flex>
          <div className="text-muted">|</div>
          <a
            href="https://blockanalitica.com/terms-of-service/"
            className="text-muted text-1"
            target="_blank"
            rel="noreferrer">
            Terms of Service
          </a>
        </Flex>
      </footer>
    </Flex>
  );
}
