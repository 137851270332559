import { useApi } from '@/api';
import { Link } from '@/components';
import EventName from '@/components/events/EventName';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { useSearchParams } from '@/hooks';
import {
  CurrencyValue,
  EtherScanPictogram,
  Flex,
  RelativeDateTime,
  WalletAddress,
  useTableProps
} from '@blockanalitica/ui';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { smartEtherscanUrl } from '../../../../utils/url.js';

export default function MarketLPEventsTable({ daysAgo, activeTab, marketId }) {
  const location = useLocation();
  const etherscanUrl = smartEtherscanUrl(location);

  //   const [searchText, setSearchText] = useSearchParams('search', '');
  const { sorting, pagination } = useTableProps({
    pageSize: 25,
    initialSorting: '-datetime'
  });

  const [date] = useSearchParams('date', '');
  const [data, error] = useApi({
    url: `/markets/${marketId}/events/`,
    queryParams: {
      type: activeTab,
      p: String(pagination.currentPage),
      p_size: String(pagination.pageSize),
      days_ago: daysAgo,
      order: sorting.currentSorting,
      date: date
    }
  });

  const content = useMemo(() => {
    if (data) {
      const columns = [
        {
          header: 'WALLET',
          getCell: (row) => (
            <WalletAddress
              options={{
                size: 'xSmall'
              }}
              address={row.wallet_address}
            />
          )
        },
        {
          header: 'EVENT',
          getCell: (row) => <EventName event={row.event} />
        },
        {
          header: 'BASE AMOUNT',
          getCell: (row) => (
            <Flex alignItems={'center'}>
              <CurrencyValue
                value={row.amount}
                options={{
                  currency: row.asset_symbol,
                  iconOpacity: 0.7,
                  leadingCurrencySymbol: false,
                  iconSize: 'xSmall'
                }}
              />
            </Flex>
          ),
          cellAlign: 'flex-end',
          headerAlign: 'flex-end',
          sortingField: 'amount'
        },
        {
          header: 'LP AMOUNT',
          getCell: (row) => (
            <Flex alignItems={'center'} gap={'space-xs'}>
              <CurrencyValue
                value={row.alt_amount}
                options={{
                  currency: row.asset_symbol,
                  iconOpacity: 0.7,
                  leadingCurrencySymbol: false,
                  iconSize: 'xSmall'
                }}
              />
            </Flex>
          ),
          cellAlign: 'flex-end',
          headerAlign: 'flex-end',
          sortingField: 'alt_amount'
        },
        {
          header: 'TIME',
          getCell: (row) => <RelativeDateTime value={row.datetime} />,
          getCellBottom: (row) => (
            <Flex gap="space-3xs" className="text-muted">
              {row.block_number}
              <a
                href={`${etherscanUrl}tx/${row.tx_hash}`}
                target="_blank"
                rel="noreferrer">
                <EtherScanPictogram size={14} />
              </a>
            </Flex>
          ),
          cellAlign: 'flex-end',
          headerAlign: 'flex-end',
          sortingField: 'order_index'
        }
      ];
      return (
        <>
          {date && (
            <h4>
              Events for{' '}
              {new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit'
              })}
            </h4>
          )}
          <Table
            rows={data.results}
            columns={columns}
            getKey={(row) => row.order_index}
            getLinkWrapper={(row) => (
              <Link daysAgo={true} to={`/wallets/${row.wallet_address}`} />
            )}
            sorting={sorting}
            pagination={{
              ...pagination,
              totalRecords: data.count
            }}
          />
        </>
      );
    }
    return <TableLoader />;
  }, [data, sorting, pagination, etherscanUrl]);

  if (error) {
    throw error;
  }

  return content;
}
