import { Link, NavLink, SidebarNavigationItem } from '@/components';
import {
  ActivityIcon,
  CoinsStackIcon,
  CryptoIcon,
  FixedSidebar,
  Flex,
  Grid1X2Icon,
  HomeIcon,
  ListIcon,
  MarketIcon,
  WalletIcon,
  XIcon,
  useIsMobile,
  useToggle
} from '@blockanalitica/ui';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import styles from './FixedSidebarLayout.module.scss';
// @ts-ignore
import BALogo from '@/assets/logos/BA-logo-full-white.svg?react';
// @ts-ignore
import Logo from '@/assets/logos/hyperdrive.svg?react';
// @ts-ignore
import Substack from '@/assets/logos/substack.svg?react';
import ExternalLinkButton from '@/components/forms/ExternalLinkButton';
import { symbolMapping, titleCase } from '@/utils';
import { NavigationData as UINavigationData } from '@blockanalitica/ui/package/esm/src/components/client-components/sidebars/FixedSidebar/components/Sidebar/Sidebar';
import classnames from 'classnames';
import { ReactElement, ReactNode, useEffect, useMemo } from 'react';

type LinkProps = {
  url: string;
  title: ReactNode;
  icon?: ReactNode;
  key?: string;
};

export interface NavigationData
  extends Omit<UINavigationData, 'title' | 'renderLink'> {
  title: ReactNode;
  renderLink: (linkProps: LinkProps) => ReactElement;
}

const createNavigationData: (network?: string) => NavigationData[] = (
  network
) => [
  {
    title: 'Networks',
    url: '/',
    isRoot: true,
    icon: <HomeIcon size={20} />,
    renderLink: ({ url, title }) => (
      <NavLink
        daysAgo={true}
        isSingleLink={true}
        href={url}
        text={title}
        className={styles.item}
        force={true}
      />
    )
  },
  {
    title: (
      <Flex gap="space-3xs" alignItems={'center'}>
        {titleCase(network)}
        <CryptoIcon name={symbolMapping[network]} size={20} />
      </Flex>
    ),
    url: '/',
    isRoot: true,
    icon: <ListIcon size={20} />,
    renderLink: ({ url, title }) => (
      <NavLink
        daysAgo={true}
        isSingleLink={true}
        href={url}
        text={title}
        className={styles.item}
      />
    )
  },
  {
    title: 'Markets',
    url: '/markets',
    isRoot: true,
    icon: <MarketIcon size={20} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink
        daysAgo={true}
        isSingleLink={true}
        href={url}
        text={title}
        className={styles.item}
      />
    )
  },
  {
    title: 'Positions',
    url: '/positions',
    isRoot: true,
    icon: <Grid1X2Icon size={20} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink
        daysAgo={true}
        isSingleLink={true}
        href={url}
        text={title}
        className={styles.item}
      />
    )
  },
  {
    title: 'Wallets',
    url: '/wallets',
    isRoot: true,
    icon: <WalletIcon size={20} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink daysAgo={true} href={url} text={title} className={styles.item} />
    )
  },
  {
    title: 'Activity',
    url: '/activity',
    isRoot: true,
    icon: <ActivityIcon size={20} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink
        daysAgo={true}
        isSingleLink={true}
        href={url}
        text={title}
        className={styles.item}
      />
    )
  },
  {
    title: 'Tokens',
    url: '/tokens',
    isRoot: true,
    icon: <CoinsStackIcon size={20} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink
        daysAgo={true}
        isSingleLink={true}
        href={url}
        text={title}
        className={styles.item}
      />
    )
  }
];

interface FixedSidebarLayoutProps {
  children?: ReactNode;
}

export default function FixedSidebarLayout({
  children
}: FixedSidebarLayoutProps) {
  const location = useLocation();
  const [sidebarOpen, toggleSidebarOpen] = useToggle();

  useEffect(() => {
    toggleSidebarOpen(false);
  }, [location.pathname, toggleSidebarOpen]);

  const currentYear = new Date().getFullYear();

  const isMobile = useIsMobile();

  const { network } = useParams();

  const navigationData = useMemo(
    () => createNavigationData(network),
    [network]
  );

  return (
    <Flex className={styles.container} gap="space-0">
      <FixedSidebar
        logo={
          <Flex
            gap={'space-m'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Link daysAgo={true} to="/" force={true}>
              <Logo className={classnames(styles.logo)} height={60} />
            </Link>
            {isMobile && (
              <ExternalLinkButton to="https://app.hyperdrive.box/">
                Launch APP
              </ExternalLinkButton>
            )}
          </Flex>
        }
        options={{
          sidebarProps: {
            containerProps: {
              backgroundColor: [
                'var(--color-transparent)',
                'var(--color-solid)',
                'var(--color-solid)'
              ],
              style: { border: 'none' }
            }
          },
          mobileTopbarProps: {
            containerProps: {
              style: {
                backgroundColor: 'var(--color-solid)',
                border: 'none'
              }
            }
          }
        }}
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebarOpen}
        footer={
          <Flex direction="column" gap="space-2xs">
            <Flex alignItems="center" gap="space-2xs">
              <a
                href="https://twitter.com/BlockAnalitica"
                className="text-muted"
                target="_blank"
                rel="noreferrer">
                <XIcon size={16} />
              </a>
              <a
                href="https://blockanalitica.substack.com/"
                className="text-muted"
                target="_blank"
                rel="noreferrer">
                <Substack height={16} />
              </a>
            </Flex>
            <a
              href="https://blockanalitica.com"
              className="text-muted"
              target="_blank"
              rel="noreferrer">
              <BALogo className={classnames(styles.logo)} height={60} />
            </a>
            <span className="text-muted text-1">
              © {currentYear}. All rights reserved.
            </span>
            <a
              href="https://blockanalitica.com/terms-of-service/"
              className="text-muted text-1"
              target="_blank"
              rel="noreferrer">
              Terms of Service
            </a>
          </Flex>
        }>
        {navigationData.map((navigationItem) => (
          <SidebarNavigationItem
            key={String(navigationItem.title)}
            item={navigationItem}
          />
        ))}
      </FixedSidebar>

      <Flex
        className={styles.contentContainer}
        gap="space-0"
        direction="column"
        justifyContent="space-between">
        <main>{children ? children : <Outlet />}</main>
      </Flex>
    </Flex>
  );
}
